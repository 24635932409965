import Button from "app/components/Button";
import Flex from "app/components/Flex";
import { H4, P1, P2 } from "app/components/Typography";
import { ANALYTICS_LOCATION, ANALYTICS_MODULE } from "constants/index";
import { useGetChallengeById } from "hooks/Challenges";
import React from "react";
import { useParams } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import { useContentSelectClassEvent } from "hooks/analytics";
import { StyledLink } from "./styles";

export default () => {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const [{ forClass }] = useGetChallengeById({
    variables: { id },
  });
  const {
    id: classId,
    level,
    style,
    instructor: { name: instructorName, slug: instructorSlug } = {},
  } = forClass;
  const { contentSelectClassEvent } = useContentSelectClassEvent({
    classData: forClass,
  });

  const handleTakeClassClick = () => {
    history.push({
      pathname: `/class/${classId}`,
      state: { previousLocation: location },
    });

    contentSelectClassEvent({
      selected_from: ANALYTICS_MODULE.challenge_detail,
      location: ANALYTICS_LOCATION.community,
    });
  };

  return (
    <>
      <Flex flexDirection="column" mt={36}>
        <H4 mb={3}>Class Info</H4>
        <Flex width="100%" flexWrap="wrap">
          <Flex width={{ _: "50%", md: "20%" }} mb={{ _: 3, md: 0 }}>
            <Flex flexDirection="column">
              <P2>INSTRUCTOR</P2>
              <StyledLink to={`/library/instructors/${instructorSlug}`}>
                <P1 fontWeight="bold" color="blue">
                  {instructorName}
                </P1>
              </StyledLink>
            </Flex>
          </Flex>
          <Flex width={{ _: "50%", md: "15%" }} mb={{ _: 3, md: 0 }}>
            <Flex flexDirection="column">
              <P2>LEVEL</P2>
              <P1 fontWeight="bold">{level}</P1>
            </Flex>
          </Flex>
          <Flex width={{ _: "50%", md: "20%" }} mb={{ _: 3, md: 0 }}>
            <Flex flexDirection="column">
              <P2>STYLE</P2>
              <P1 fontWeight="bold">{style}</P1>
            </Flex>
          </Flex>
          <Flex
            width={{ _: "100%", md: "45%" }}
            justifyContent={{ _: "center", md: "flex-end" }}
          >
            <Button
              onClick={handleTakeClassClick}
              width={{ _: "100%", sm: "90%", md: "80%" }}
            >
              TAKE CLASS
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
