import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;

  :hover {
    opacity: 0.8;
    text-decoration: none;
  }
`;
